/**
 * entzettelt
 * - copyright 2019-2024 Hill-Commerce GmbH
 * - developed by Manuel Pohl (m.pohl@hill-commerce.de)
 */

import DependencyWalker from '@/classes/Core/Share/helpers/DependencyWalker'
import ShareHelper      from '@/classes/Core/Share/helpers/ShareHelper'

export default class Share
{

    constructor( core )
    {
        if( !Share.instance )
        {

            this.core = core
            this.logger = core.getLogger()
            this.store = core.getStore()
            this.f = core.f()
            this.eventManager = core.getEventManager()
            this.baseClassHelper = core.getBaseClassHelper()

            if( undefined === this.baseClassHelper )
            {
                this.eventManager.append( 'on-baseclasses-available', () =>
                {
                    this.baseClassHelper = core.getBaseClassHelper()
                } )
            }

            this.uuid = core.getUuid()
            this.cryptoCore = core.getCryptoCore()
            this.cryptoHelper = core.getCryptoHelper()
            this.queueWorker = core.getQueueWorker()
            this.database = core.getDatabase()
            this.client = core.getClient()

            this.colleagues = []

            this.ui = core.getUi()
            this.ready = false
            this.queueForType = []

            this.init()

            this.dependencyWalker = new DependencyWalker( this )
            this.shareHelper = new ShareHelper( this )

            Share.instance = this


        }

        return Share.instance
    }

    /*eslint-disable*/
    init()
    {

        setTimeout( () =>
        {

            this.baseClassHelper
                .get( 'colleague' )
                .cacheHeatup()

            this.setEventHandler()

        }, 2000 )

        //this.refreshColleagues()

    }

    shareHelperReady()
    {
        return this.shareHelper.ready
    }


    awaitShareHelperReady()
    {
        return new Promise( resolve =>
        {

            if( this.shareHelperReady() )
            {
                return resolve()
            }
            else
            {
                setTimeout( () =>
                {
                    return resolve( this.awaitShareHelperReady() )
                }, 300 )
            }

        } )
    }

    setEventHandler()
    {
        this.eventManager.append( 'on-filled-state-colleague', () =>
        {
            if( true === this.store.getters.authorized )
            {
                this.ready = false
                this.refreshColleagues()
            }
            else
            {
                this.eventManager.append( 'on-login-state-change', () =>
                {
                    this.ready = false
                    this.refreshColleagues()
                } )
            }
        } )

        this.eventManager.append( 'on-refresh-cache-colleague', () =>
        {
            this.ready = false
            this.refreshColleagues()
        } )

    }

    destruct()
    {
        delete Share.instance
    }

    refreshColleagues()
    {

        if( 'filled' === this.baseClassHelper
                             .get( 'colleague' ).state )
        {

            let scopes     = [ 'cache', 'archive' ],
                colleagues = this.baseClassHelper
                                 .get( 'colleague' )
                                 .getCache(),
                list       = []

            for( let s in scopes )
            {
                /* eslint-disable-next-line no-unused-vars */
                for( const [ localId, colleague ] of colleagues[ scopes[ s ] ] )
                {
                    if( 2 === colleague.state )
                    {
                        list.push( colleague )
                    }
                }
            }

            this.colleagues = list
            this.eventManager.dispatch( 'on-share-ready', this )
            this.eventManager.dispatch( 'on-share-refresh' )
            this.ready = true
            this.eventManager.dispatchIndexed( 'on-share-refresh-component' )

        }

    }

    awaitReadiness()
    {
        return new Promise( resolve =>
        {
            if( this.ready )
            {
                return resolve()
            }
            else
            {
                this.core.getCoreTimer()
                    .addTimeout( 'share-wait-ready', 300, () =>
                    {
                        return resolve( this.awaitReadiness() )
                    } )
            }
        } )
    }

    isShared( object )
    {

        return this.shareHelper.isShared( object )

    }

    sharedWith( object, uuid )
    {

        if( this.f.isset( object._keys ) )
        {

            for( let k in object._keys )
            {
                if( object._keys[ k ].uuid === uuid )
                {
                    return true
                }
            }

            return false

        }

        if( 'list' === object.type
            && Array.isArray( object.lists ) )
        {
            for( let l in object.lists )
            {
                if( this.sharedWith( object.lists[ l ], uuid ) )
                {
                    return true
                }
            }
        }

        return false

    }

    shareList( object )
    {
        return this.shareHelper.shareList( object )
    }

    getOwnerColleague( object )
    {
        return this.shareHelper.getOwnerColleague( object )
    }

    _keyPosition( keyset, uuid )
    {
        for( let k in keyset )
        {
            if( keyset[ k ].uuid === uuid )
            {
                return k
            }
        }
        return null
    }

    getColleague( uuid )
    {
        for( let c in this.colleagues )
        {
            if( this.colleagues[ c ].uuid === uuid )
            {
                return this.colleagues[ c ].colleagueId
            }
        }
        return false
    }

    resolveObject( element )
    {

        return new Promise( resolve =>
        {

            switch( element.type )
            {
                case 'list':
                    return resolve( this.baseClassHelper
                                        .get( 'list' )
                                        .getListById( element.referenceKey, element.localId || element.id, 'cache' ) )
                default:
                    return resolve( this.baseClassHelper.getObjectById( element.localId || element.id ) )
            }

        } )

    }

    shareCount( colleague )
    {
        return this.shareHelper.shareCount( colleague )
    }

    _decryptFirst( element )
    {
        return new Promise( resolve =>
        {

            if( undefined === element.localKey )
            {
                this.cryptoHelper.decrypt( element.object )
                    .then( decryptedElement =>
                    {
                        decryptedElement.localKey = this.cryptoHelper.getOwnElementKey( element.object )
                        return resolve( decryptedElement )
                    } )
            }
            else
            {
                return resolve( element )
            }

        } )
    }

    _rekey( element, colleague )
    {

        for( let k in element._keys )
        {
            if( element._keys[ k ].uuid === colleague.uuid )
            {
                return element._keys[ k ].key
            }
        }
        return this.cryptoHelper.rekey( element.localKey, colleague.publicKey )

    }

    keyUpdate( mode, element, colleague )
    {

        return new Promise( resolve =>
        {

            this.resolveObject( element )
                .then( elm =>
                {

                    if( undefined !== elm )
                    {

                        let keyset     = JSON.parse( JSON.stringify( elm._keys ) ),
                            deleteKeys = [],
                            idx        = undefined !== colleague ? this._keyPosition( keyset, colleague.uuid ) : null,
                            objectKey  = false

                        this._decryptFirst( element )
                            .then( decryptedElement =>
                            {

                                element = decryptedElement

                                switch( mode )
                                {
                                    case 'add':
                                        objectKey = this._rekey( element, colleague )
                                        if( false !== objectKey )
                                        {
                                            if( null === idx )
                                            {
                                                keyset.push( {
                                                    uuid: colleague.uuid,
                                                    key : objectKey
                                                } )
                                            }
                                            else
                                            {
                                                keyset[ idx ] = {
                                                    uuid: colleague.uuid,
                                                    key : objectKey
                                                }
                                            }
                                        }
                                        break
                                    case 'remove':
                                        deleteKeys.push( colleague.uuid )
                                        if( null !== idx )
                                        {
                                            keyset.splice( idx, 1 )
                                        }
                                        break
                                    case 'removeall':
                                        let keysetNew = []
                                        for( let k in keyset )
                                        {
                                            if( keyset[ k ].uuid === this.store.getters.uuid )
                                            {
                                                keysetNew.push( keyset[ k ] )
                                            }
                                            else
                                            {
                                                deleteKeys.push( keyset[ k ].uuid )
                                            }
                                        }
                                        keyset = keysetNew
                                        break

                                }

                                elm._keys = keyset

                                if( 0 < deleteKeys.length )
                                {

                                    let message = {
                                        method    : 'objects.deleteKeys',
                                        id_local  : element.localId,
                                        deleteList: deleteKeys
                                    }

                                    let jobId = this.uuid.generate()
                                    this.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )

                                }

                                return resolve( {
                                    element: elm,
                                    keys   : keyset
                                } )

                            } )

                    }
                    else
                    {
                        return resolve( null )
                    }

                } )
                .catch( () =>
                {
                    return resolve( null )
                } )

        } )

    }

    writeShares( sharesList )
    {
        return new Promise( resolve =>
        {

            this.database.writeSharesList( sharesList )
                .then( () =>
                {

                    let jobId      = this.uuid.generate(),
                        remoteList = []

                    for( let s in sharesList )
                    {
                        remoteList.push( {
                            localId : sharesList[ s ].localId,
                            remoteId: sharesList[ s ].remoteId,
                            keys    : [ { colleagueId: sharesList[ s ].idColleague } ]
                        } )
                    }

                    this.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( {
                        method: 'network.writeShares',
                        list  : remoteList
                    } ) )

                    return resolve()

                } )

        } )
    }

    removeShare( sharesList )
    {

        let jobId      = this.uuid.generate(),
            remoteList = [],
            dbRemovals = []

        for( let s in sharesList )
        {

            dbRemovals.push( {
                localId    : sharesList[ s ].localId,
                idColleague: sharesList[ s ].idColleague
            } )

            remoteList.push( {
                localId : sharesList[ s ].localId,
                remoteId: sharesList[ s ].remoteId,
                keys    : [ { colleagueId: sharesList[ s ].idColleague } ]
            } )

        }

        this.database.deleteSharesList( dbRemovals )
            .then( () =>
            {

                this.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( {
                    method: 'network.removeShares',
                    list  : remoteList
                } ) )

            } )

    }

    _getColleagueKey( keyset, uuid )
    {
        for( let k in keyset )
        {
            if( keyset[ k ].uuid === uuid )
            {
                return keyset[ k ].key
            }
        }
        return false
    }

    _remoteKeyUpdates( keyUpdates )
    {

        let message = {
            method: 'objects.updateKeyList',
            list  : keyUpdates
        }

        let jobId = this.uuid.generate()
        this.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )

    }

    _remoteKeysRemove( remoteUpdates )
    {

        let message = {
            method: 'objects.dropKeys',
            list  : remoteUpdates
        }

        let jobId = this.uuid.generate()
        this.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )

    }

    _remoteAllKeysRemove( remoteUpdates )
    {

        let message = {
            method: 'objects.dropAllForeignKeys',
            list  : remoteUpdates
        }

        let jobId = this.uuid.generate()
        this.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )

    }

    /*eslint-disable*/
    rehashCaches( registryUpdates )
    {
        return new Promise( resolve =>
        {

            let refsDone = []
            for( let k in registryUpdates )
            {

                if( 'list' === registryUpdates[ k ].type
                    && -1 === refsDone.indexOf( registryUpdates[ k ].referenceKey ) )
                {

                    let container = this.baseClassHelper
                                        .get( 'list' )
                                        .registry
                                        .cache
                                        .get( registryUpdates[ k ].referenceKey )

                    if( undefined !== container )
                    {
                        for( let l in container.lists )
                        {
                            this.baseClassHelper.get( 'list' )
                                .refreshCache( container.lists[ l ].localId )
                        }

                        refsDone.push( registryUpdates[ k ].referenceKey )

                    }

                }
                else
                {
                    let element = this.baseClassHelper.getObjectById( registryUpdates[ k ].localId )
                    if( undefined !== element )
                    {

                        this.baseClassHelper.get( registryUpdates[ k ].type )
                            .refreshCache( registryUpdates[ k ].localId )

                    }
                }

            }

            return resolve()

        } )
    }

    performKeyUpdate( updates )
    {

        return new Promise( resolve =>
        {

            let registryUpdates = [],
                keyUpdates      = {},
                regList         = []

            for( let u in updates )
            {

                if( -1 === regList.indexOf( updates[ u ].localId ) )
                {
                    regList.push( updates[ u ].localId )
                    registryUpdates.push( {
                        type        : updates[ u ].update.element.type,
                        localId     : updates[ u ].localId,
                        referenceKey: updates[ u ].referenceKey
                    } )
                }

                if( undefined === keyUpdates[ updates[ u ].localId ] )
                {
                    keyUpdates[ updates[ u ].localId ] = []
                }

                let key = this._getColleagueKey( updates[ u ].update.keys, updates[ u ].colleagueUuid )
                if( false !== key )
                {
                    keyUpdates[ updates[ u ].localId ].push( {
                        idColleague  : updates[ u ].idColleague,
                        colleagueUuid: updates[ u ].colleagueUuid,
                        key          : key,
                        remoteId     : updates[ u ].remoteId
                    } )
                }

            }

            this.database.appendObjectKeys( keyUpdates, this.store.getters.uuid )
                .then( () =>
                {

                    this.rehashCaches( registryUpdates )
                        .then( () =>
                        {

                            this._remoteKeyUpdates( keyUpdates )
                            return resolve()

                        } )

                } )

        } )

    }

    _prepareShareableObjectsList( shareWith, elementList, optional, method )
    {

        let ids        = [],
            shareables = [],
            result     = {
                create: [],
                update: [],
                delete: [],
                _count: 0
            },
            objects    = {}

        for( let e in elementList )
        {
            switch( elementList[ e ].type )
            {
                case 'list':
                    if( -1 === ids.indexOf( elementList[ e ].referenceKey ) )
                    {
                        ids.push( elementList[ e ].referenceKey )
                        shareables.push( {
                            idReference: elementList[ e ].referenceKey,
                            objectType : elementList[ e ].type,
                            optional   : optional,
                            sharedWith : []
                        } )
                        objects[ elementList[ e ].referenceKey ] = elementList[ e ]
                    }
                    break
                default:
                    if( -1 === ids.indexOf( elementList[ e ].localId ) )
                    {
                        ids.push( elementList[ e ].localId )
                        shareables.push( {
                            idReference: elementList[ e ].localId,
                            objectType : elementList[ e ].type,
                            optional   : optional,
                            sharedWith : []
                        } )
                        objects[ elementList[ e ].localId ] = elementList[ e ]
                    }
                    break
            }
        }

        for( let s in shareables )
        {

            let shareId = this.baseClassHelper
                              .get( 'share' ).registry.byReference
                              .get( shareables[ s ].idReference ),
                share   = this.baseClassHelper
                              .get( 'share' )
                              .getById( shareId )

            if( undefined !== shareId
                && undefined !== share )
            {

                for( let sw in shareWith )
                {
                    if( 'add' === method )
                    {
                        if( -1 === share.sharedWith.indexOf( shareWith[ sw ].uuid ) )
                        {
                            share.sharedWith.push( shareWith[ sw ].uuid )
                        }
                        result.update.push( this.f.deref( share ) )
                        result._count++
                    }
                    if( 'remove' === method
                        && -1 < share.sharedWith.indexOf( shareWith[ sw ].uuid ) )
                    {
                        this.f.removeFromArray( share.sharedWith, shareWith[ sw ].uuid )
                        result.update.push( this.f.deref( share ) )
                        result._count++
                    }
                }

            }
            else
            {
                //&& true !== objects[ shareables[ s ].idReference ].archived )
                if( undefined !== objects[ shareables[ s ].idReference ] )
                {
                    for( let sw in shareWith )
                    {
                        shareables[ s ].sharedWith.push( shareWith[ sw ].uuid )
                    }
                    result.create.push( this.f.deref( shareables[ s ] ) )
                    result._count++
                }
            }

        }

        ids = null
        shareables = null
        objects = null

        return result

    }

    prepareShares( shareWith, elementList, optional, method )
    {

        return new Promise( resolve =>
        {

            this.baseClassHelper
                .get( 'share' )
                .cacheHeatup()
                .then( () =>
                {

                    return resolve( this._prepareShareableObjectsList( shareWith, elementList, optional, method ) )

                } )

        } )

    }

    writeShareables( shareables )
    {

        return new Promise( resolve =>
        {

            let total     = shareables._count,
                step      = 0,
                baseClass = this.baseClassHelper.get( 'share' )

            this.ui.updateProgress( total, step )

            for( let method in shareables )
            {
                if( '_count' !== method )
                {
                    for( let s in shareables[ method ] )
                    {

                        switch( method )
                        {
                            case 'create':
                                baseClass.create( shareables[ method ][ s ] )
                                break
                            case 'update':
                                baseClass.update(
                                    shareables[ method ][ s ],
                                    shareables[ method ][ s ].localId,
                                    shareables[ method ][ s ].remoteId,
                                    shareables[ method ][ s ].timestamp,
                                    shareables[ method ][ s ].localKey )
                                break
                            case 'delete':
                                baseClass.delete( shareables[ method ][ s ].localId )
                                break
                        }

                    }

                    step++
                    this.ui.updateProgress( total, step )

                }
            }

            return resolve()

        } )

    }

    _bundleLists( elementList )
    {

        let newList  = [],
            localIds = []

        for( let e in elementList )
        {
            if( -1 === localIds.indexOf( elementList[ e ].localId ) )
            {

                if( elementList[ e ].type === 'list'
                    && this.uuid.validate( elementList[ e ].localId ) )
                {

                    let container = this.baseClassHelper
                                        .get( 'list' )
                                        .registry
                                        .cache
                                        .get( elementList[ e ].referenceKey )
                    if( undefined !== container
                        && -1 === localIds.indexOf( container.localId ) )
                    {
                        newList.push( container )
                        localIds.push( container.localId )
                    }

                }
                else
                {
                    newList.push( elementList[ e ] )
                }

                localIds.push( elementList[ e ].localId )

            }
        }

        return newList

    }

    deepResolve( elementList, result, localIds )
    {
        return new Promise( resolve =>
        {

            result = result || []
            localIds = localIds || []

            if( 0 < elementList.length )
            {
                let elm = elementList.shift()
                if( -1 === localIds.indexOf( elm.localId )
                    && this.uuid.validate( elm.localId ) )
                {
                    localIds.push( elm.localId )
                    this.dependencyWalker.resolveDependencies( [ elm ], {}, true )
                        .then( items =>
                        {

                            for( let i in items )
                            {
                                let item = items[ i ]
                                if( -1 === localIds.indexOf( item.localId ) )
                                {
                                    result.push( item )
                                    localIds.push( item.localId )
                                }
                            }
                            return resolve( this.deepResolve( elementList, result, localIds ) )
                        } )
                }
                else
                {
                    return resolve( this.deepResolve( elementList, result, localIds ) )
                }
            }
            else
            {
                return resolve( result )
            }

        } )
    }

    _cleanDoubles( elementList )
    {
        let newList = []
        for( let e in elementList )
        {
            if( this.uuid.validate( elementList[ e ].localId )
                && !newList.find( o => o.localId === elementList[ e ].localId ) )
            {
                newList.push( elementList[ e ] )
            }
        }
        return newList
    }

    /*eslint-disable*/
    share( colleagueUuid, elms, shareDependencies )
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.shareHelper.resolveShareWith( colleagueUuid )
                .then( ( shareWith ) =>
                {

                    this.dependencyWalker.resolveDependencies( elms, shareDependencies )
                        .then( elementList =>
                        {

                            elementList = this._cleanDoubles( elementList )
                            let bundle = this._bundleLists( elementList )

                            this.deepResolve( bundle )
                                .then( result =>
                                {

                                    for( let r in result )
                                    {
                                        if( this.uuid.validate( result[ r ].localId )
                                            && !elementList.find( o => o.localId === result[ r ].localId ) )
                                        {
                                            elementList.push( result[ r ] )
                                        }
                                    }

                                    this.prepareShares( shareWith, elementList, shareDependencies, 'add' )
                                        .then( shareables =>
                                        {

                                            this.writeShareables( shareables )
                                                .then( result =>
                                                {

                                                    /*
                                                    for( let s in shareables )
                                                    {
                                                        for( let i in shareables[ s ] )
                                                        {
                                                            this.eventManager.dispatchIndexed( 'on-share-queue-done-' + shareables[ s ][ i ].idReference )
                                                        }
                                                    }
                                                    */

                                                    return resolve( result )

                                                } )

                                        } )


                                } )

                        } )

                } )

        } )

    }

    unshare( colleagueUuid, elementList )
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.shareHelper.resolveShareWith( colleagueUuid )
                .then( shareWith =>
                {

                    this.prepareShares( shareWith, elementList, undefined, 'remove' )
                        .then( shareables =>
                        {

                            for( let e in elementList )
                            {

                                let unshare = {
                                    idReference: elementList[ e ].localId,
                                    objectType : elementList[ e ].type,
                                    sharedWith : shareWith,
                                    timestamp  : Date.now(),
                                    update     : Date.now()
                                }

                                this.baseClassHelper.get( 'unshare' )
                                    .create( unshare )

                            }

                            this.writeShareables( shareables )
                                .then( result =>
                                {

                                    /*
                                    for( let s in shareables )
                                    {
                                        for( let i in shareables[ s ] )
                                        {
                                            this.eventManager.dispatchIndexed( 'on-share-queue-done-' + shareables[ s ][ i ].idReference )
                                        }
                                    }
                                    */

                                    return resolve( result )

                                } )

                        } )


                } )

        } )

    }

    hardKeyReset( colleagueUuid )
    {
        this.shareHelper.resolveShareWith( colleagueUuid )
            .then( shareWith =>
            {

                for( let s in shareWith )
                {

                    let message = {
                        method     : 'objects.dropRemainingKeys',
                        idColleague: shareWith[ s ].colleagueId
                    }

                    let jobId = this.uuid.generate()
                    this.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )

                }

            } )
    }

    removeColleague( localId )
    {

        return new Promise( resolve =>
        {

            let colleague = this.shareHelper.getColleague( localId )
            if( false !== colleague )
            {
                this.baseClassHelper
                    .getAllObjects()
                    .then( allObjects =>
                    {

                        let shares = []

                        for( let a in allObjects )
                        {

                            if( allObjects[ a ].type !== 'colleague'
                                && allObjects[ a ].type !== 'message' )
                            {
                                if( this.sharedWith( allObjects[ a ], colleague.uuid ) )
                                {

                                    shares.push( allObjects[ a ] )

                                }
                            }

                        }

                        this.unshare( colleague.uuid, shares )
                            .then( () =>
                            {

                                let message = {
                                    method      : 'network.deleteColleague',
                                    id_colleague: colleague.colleagueId
                                }

                                this.client.request( message )
                                    .then( () =>
                                    {

                                        return resolve()

                                    } )

                            } )

                    } )

            }
            return resolve()

        } )

    }

    unshareAll( elementList )
    {

        return new Promise( ( resolve, reject ) =>
        {

            let ownUuid  = this.store.getters.uuid,
                unshares = [],
                promises = []

            for( let e in elementList )
            {

                if( Array.isArray( elementList[ e ].lists ) )
                {
                    for( let l in elementList[ e ].lists )
                    {
                        if( Array.isArray( elementList[ e ].lists[ l ]._keys ) )
                        {
                            for( let k in elementList[ e ].lists[ l ]._keys )
                            {
                                if( elementList[ e ].lists[ l ]._keys[ k ].uuid !== ownUuid )
                                {
                                    unshares.push( {
                                        elements     : elementList[ e ].lists[ l ],
                                        colleagueUuid: elementList[ e ].lists[ l ]._keys[ k ].uuid
                                    } )
                                }
                            }
                        }
                    }
                }
                else
                {
                    for( let k in elementList[ e ]._keys )
                    {
                        if( elementList[ e ]._keys[ k ].uuid !== ownUuid )
                        {
                            unshares.push( {
                                elements     : elementList[ e ],
                                colleagueUuid: elementList[ e ]._keys[ k ].uuid
                            } )
                        }
                    }
                }

            }

            if( 0 < unshares.length )
            {

                let done = 0
                this.ui.updateProgress( unshares.length, 0 )

                for( let u in unshares )
                {
                    promises.push( () =>
                    {
                        return new Promise( resolve =>
                        {
                            this.unshare( unshares[ u ].colleagueUuid, [ unshares[ u ].elements ] )
                                .then( () =>
                                {
                                    done++
                                    this.ui.updateProgress( unshares.length, done )
                                    return resolve()
                                } )
                        } )
                    } )
                }

            }

            this.f
                .promiseRunner( promises )
                .then( () =>
                {

                    this.ui.updateProgress( unshares.length, unshares.length )
                    return resolve()

                } )

        } )
    }

    _resolveElementList( colleagueUuid )
    {
        return new Promise( resolve =>
        {

            let elementList = [],
                promises    = [],
                resolved    = [],
                todo        = [ 'class', 'group', 'yeargroup', 'student', 'note', 'list', 'todo', 'date', 'team', 'avatar' ]

            while( 0 < todo.length )
            {
                let step = todo.shift()
                promises.push( () =>
                {
                    return new Promise( resolve =>
                    {

                        this.baseClassHelper
                            .get( step )
                            .getPreparedCache()
                            .then( list =>
                            {

                                for( const [ localId, element ] of list )
                                {
                                    if( -1 === resolved.indexOf( localId )
                                        && this.sharedWith( element, colleagueUuid ) )
                                    {
                                        resolved.push( localId )
                                        elementList.push( element )
                                    }
                                }

                                return resolve()

                            } )

                    } )
                } )

            }

            this.f.promiseRunner( promises )
                .then( () =>
                {
                    return resolve( elementList )
                } )

        } )
    }

    unshareAllForColleague( colleagueUuid )
    {

        return new Promise( resolve =>
        {

            this._resolveElementList( colleagueUuid )
                .then( list =>
                {

                    this.unshare( colleagueUuid, list )
                        .then( () =>
                        {
                            this.hardKeyReset( colleagueUuid )
                            return resolve()
                        } )

                } )

        } )

    }

    _shadowCopyExists( studentLocalId, referenceLocalId )
    {

        let allCopies = this.baseClassHelper.get( 'shadowCopy' )
                            .getCache( 'cache' )
        /*eslint-disable*/
        for( const [ a, copy ] of allCopies )
        {
            if( copy.studentLocalId === studentLocalId
                && copy.referenceLocalId === referenceLocalId )
            {

                return true

            }
        }
        /*eslint-enable*/
        return false

    }

    _getShadowCopy( studentLocalId, referenceLocalId )
    {

        let allCopies = this.baseClassHelper
                            .get( 'shadowCopy' )
                            .getCache( 'cache' )

        /*eslint-disable*/
        for( const [ a, copy ] of allCopies )
        {
            if( copy.studentLocalId === studentLocalId
                && copy.referenceLocalId === referenceLocalId )
            {

                return copy

            }
        }
        /*eslint-enable*/
        return false

    }

    createShadowCopy( source, shareWith, studentEditable )
    {

        return new Promise( resolve =>
        {

            let element = undefined === source.object ? source : this.baseClassHelper.getObjectById( source.id )

            if( undefined !== element )
            {

                if( !this._shadowCopyExists( shareWith.studentLocalId, element.localId ) )
                {

                    let shadowCopyClass = this.baseClassHelper.get( 'shadowCopy' ),
                        shadowCopy      = {
                            elementType     : element.type,
                            referenceLocalId: element.localId,
                            referenceKey    : element.referenceKey,
                            timestamp       : element.timestamp,
                            update          : element.update,
                            studentLocalId  : shareWith.studentLocalId,
                            studentEditable : true === studentEditable
                        }

                    shadowCopyClass.create( shadowCopy )
                    return resolve()

                }
                else
                {
                    return resolve()
                }

            }
            else
            {
                return resolve()
            }

        } )

    }

    /*eslint-disable*/
    shareWithStudent( studentId, elms, shareDependencies )
    {

        return new Promise( resolve =>
        {

            this.shareHelper.resolveShareWithStudent( studentId )
                .then( ( shareWith ) =>
                {

                    this.dependencyWalker.resolveDependencies( elms, shareDependencies )
                        .then( elementList =>
                        {

                            let promises        = [],
                                total           = elementList.length,
                                step            = 0,
                                studentEditable = shareDependencies[ 'isStudentEditable' ] === true

                            this.ui.updateProgress( total, step )

                            for( let s in shareWith )
                            {

                                for( let e in elementList )
                                {
                                    promises.push( () =>
                                    {
                                        return this.createShadowCopy( elementList[ e ], shareWith[ s ], studentEditable )
                                                   .then( () =>
                                                   {
                                                       step++
                                                       this.ui.updateProgress( total, step )
                                                   } )
                                    } )
                                }
                            }

                            this.f.promiseRunner( promises )
                                .then( () =>
                                {
                                    return resolve()
                                } )

                        } )

                } )

        } )

    }

    _shadowCopyId( localId, studentLocalId )
    {
        return 'shad-'
               + localId + '-'
               + this.f.hashCyrB53( studentLocalId )
    }

    _remoteShadowCopyDeletion( deletions )
    {

        return new Promise( resolve =>
        {

            if( 0 < deletions.length )
            {

                let message = {
                        method    : 'objects.deleteShadowCopyElements',
                        deleteList: deletions
                    },
                    jobId   = this.uuid.generate()

                this.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )

                return resolve()

            }
            else
            {
                return resolve()
            }

        } )

    }

    _shadowCopyDeletion( remotes, locals )
    {

        return new Promise( resolve =>
        {

            for( let l in locals )
            {
                this.baseClassHelper.get( locals[ l ].type )
                    .deleteShadowCopy( locals[ l ].localId )
            }

            return resolve( this._remoteShadowCopyDeletion( remotes ) )

        } )

    }

    removeShadowCopy( element, studentAccess )
    {
        return new Promise( resolve =>
        {

            let removables           = [],
                promises             = [],
                shadowDeletions      = [],
                localShadowDeletions = []

            if( undefined !== element.lists )
            {
                for( let l in element.lists )
                {
                    shadowDeletions.push( this._shadowCopyId( element.lists[ l ].localId, studentAccess.studentLocalId ) )
                    localShadowDeletions.push( {
                        type   : element.type,
                        localId: this._shadowCopyId( element.lists[ l ].localId, studentAccess.studentLocalId )
                    } )
                    let shadowCopy = this._getShadowCopy( studentAccess.studentLocalId, element.lists[ l ].localId )
                    if( false !== shadowCopy )
                    {
                        removables.push( shadowCopy )
                    }
                }
            }
            else
            {
                shadowDeletions.push( this._shadowCopyId( element.localId, studentAccess.studentLocalId ) )
                localShadowDeletions.push( {
                    type   : element.type,
                    localId: this._shadowCopyId( element.localId, studentAccess.studentLocalId )
                } )
                let shadowCopy = this._getShadowCopy( studentAccess.studentLocalId, element.localId )
                if( false !== shadowCopy )
                {
                    removables.push( shadowCopy )
                }
            }

            if( 0 < removables.length )
            {
                let shadowCopyClass = this.baseClassHelper.get( 'shadowCopy' )
                for( let r in removables )
                {
                    promises.push( () =>
                    {
                        return shadowCopyClass.delete( removables[ r ].localId ) //, removables[ r ].remoteId )
                    } )
                }
            }

            if( 0 < shadowDeletions.length )
            {
                promises.push( () =>
                {
                    return this._shadowCopyDeletion( shadowDeletions, localShadowDeletions )
                } )
            }

            this.f.promiseRunner( promises )
                .then( () =>
                {
                    return resolve()
                } )

        } )
    }

    unshareWithStudent( studentId, elms )
    {
        return new Promise( resolve =>
        {

            this.shareHelper.resolveShareWithStudent( studentId )
                .then( ( shareWith ) =>
                {

                    let promises = [],
                        total    = elms.length,
                        step     = 0

                    this.ui.updateProgress( total, step )

                    for( let s in shareWith )
                    {
                        for( let e in elms )
                        {
                            let element = elms[ e ]
                            promises.push( () =>
                            {

                                return this.removeShadowCopy( element, shareWith[ s ] )
                                           .then( () =>
                                           {
                                               step++
                                               this.ui.updateProgress( total, step )
                                           } )

                            } )
                        }

                    }

                    this.f.promiseRunner( promises )
                        .then( () =>
                        {
                            return resolve()
                        } )

                } )

        } )
    }

}